.total_tache{
    font-weight: 300;
    font-size: 14px;
    color: #555;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    padding: 10px;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.row_select{
    display: flex;
    align-items: center;
}
.total_tache:hover{
    transform: translateY(-10px);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
}

.filter-transition-enter {
    opacity: 0;
    transform: translateY(-10px);
  }
  
  .filter-transition-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms, transform 300ms;
  }
  
  .filter-transition-exit {
    opacity: 1;
    transform: translateY(0);
  }
  
  .filter-transition-exit-active {
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 300ms, transform 300ms;
  }

@media screen and (max-width: 568px){
    .row_select{
        width: 100%;
        justify-content: center;
    }
    .total_tache{
        display: none;
    }
}