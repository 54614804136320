.filterTache{
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
    .filter_row{
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100%;
        label{
            font-size: 13px;
            color: #555;
        }
    }
}