.detail-tache-card {
    padding: 20px;
  }
  
  .detail-tache-inner-card {
    margin-bottom: 16px;
  }
  
  .detail-tache-paragraph {
    margin-bottom: 12px;
  }
  