.declarationForm{
    width: 100%;
    padding: 20px;
    background: #fff;
    border-radius: 5px;
    .client-arrow{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0;
        .row-arrow{
            .icon-arrow{
              font-size: 1.2rem;
              color: rgb(0, 119, 255);
              cursor: pointer;
            }
        }
    }
    .declaration-wrapper{
        width: 100%;
        display: flex;
        gap: 25px;
        .declaration-left{
            flex: 1;
        }
        .declaration-right{
            width: 100%;
            overflow-x: scroll;
            flex: 1.5;
            .declaration-modal-templ{
                display: flex;
                align-items: center;
                .templ-icon{
                    background: #2196F3;
                    height: 30px;
                    width: 30px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: 10px;
                    margin-top: 10px;
                    color: #fff;
                    cursor: pointer;
                    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
                    transition: transform 0.2s
                }
            }
        }
    }
}