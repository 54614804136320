@import '../../style.scss';

.topbar {
    position: sticky;
    top: 0;
    left: 0;
    padding: 10px 40px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 999;
    border-bottom: 1px solid #cfcfcf;
    @include mobileXR{
      padding: 15px 20px;
    }
  
    .topbar-left {
      display: flex;
      align-items: center;
      gap: 7px;
      cursor: pointer;
      .topbar-img {
        height: 45px;
        width: 45px;
        border-radius: 50%;
      }
      .logo{
        font-size: 1.3rem;
        text-transform:uppercase;
        font-weight: 900;
        color: rgb(3, 3, 141);
        letter-spacing: 2px;
        display: flex;
        align-items: center;
        gap: 3px;
        .logo-d{
          width: 33px;
          height: 33px;
          background: rgb(3, 3, 141);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          font-size: 22px;
          font-weight: bold;

        }
      }
    }
    .topbar-right {
      display: flex;
      align-items: center;
      gap: 15px;
      hr{
        height: 20px;
        color: rgb(201, 201, 201);
      }
      .topbar-user-rows{
        display: flex;
        align-items: center;
        gap: 8px;
        .user-logo{
          height: 35px;
          width: 35px;
          border-radius: 50%;
        }
        .topbar-name-rows{
          display: flex;
          flex-direction: column;
          gap: 1px;
          .topbar-name{
            font-weight: 900;
            font-size: .7rem;
            color: rgb(3, 3, 141);
          }
          .topbar-sous-name{
            font-size: .6rem;
          }
        }
      }
      .topBar-trait{
        @include mobileXR{
          display: none;
        }
        .topbar-icon{
          font-size: 1rem;
        }
      }
      .hamburger {
        width: 22px;
        height: 18px;
        display: none;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
        @include mobileXR{
          display: flex;
        }
      
        .line {
          width: 100%;
          height: 2px;
          background-color: black;
          transition: all 0.3s ease;
        }
      
        &.open {
          .line {
            background-color: red;  // Les lignes deviennent rouges lorsque le bouton est ouvert
          }
      
          .line:nth-child(1) {
            transform: rotate(45deg) translate(6px, 6px);
          }
      
          .line:nth-child(2) {
            opacity: 0;
          }
      
          .line:nth-child(3) {
            transform: rotate(-45deg) translate(6px, -6px);
          }
        }
      }
      
      
    }
  }
  