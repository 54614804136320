.upload-card {
    width: 100%; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px; 
    border-radius: 12px; 
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); 
    background: #f9f9f9; 
    transition: box-shadow 0.3s ease-in-out;
}

.upload-card:hover {
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15);
}

.upload-title {
    font-size: 1.3rem!important;
    text-align: center; 
    margin-bottom: 50px!important; 
    font-weight: 600;
}

.upload-area {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed #007bff; 
    border-radius: 8px; 
    padding: 20px; 
    transition: border-color 0.3s ease-in-out;
}

.upload-area:hover {
    border-color: #0056b3;
}

.upload-button {
    width: 100%; 
    border-radius: 6px; 
    background-color: #007bff; 
    color: #fff; 
    font-weight: 500; 
    transition: background-color 0.3s ease-in-out;
}

.upload-button:hover {
    background-color: #0056b3;
}

.upload-submit {
    width: 100%; 
    border-radius: 6px; 
    margin-top: 12px; 
}
