.sidebar {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 57px);
  position: sticky;
  top: 57px;
  border-right: 1px solid #cfcfcf;
  background: #fff;
  padding: 10px;
  transition: width 0.3s ease, padding 0.3s ease, transform 0.3s ease;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Scrollbar styles */
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #c2c2c2;
  border-radius: 10px;
  border: 1px solid #f1f1f1;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #8d8d8d;
}

.sidebar-toggle {
  background:#f8f8f8f1;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 10px;
  margin: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 20px;
  right: -20px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  color: #000;
  font-size: 15px;
  z-index: 1001;
}

.sidebar-toggle:hover {
  background-color: #f0f0f0;
  transform: scale(1.1);
  color: #000;
}

.ant-menu-item,
.ant-menu-submenu-title {
  font-size: 13px;
  padding: 10px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.ant-menu-item:hover .ant-menu-item-icon,
.ant-menu-submenu:hover .ant-menu-submenu-title {
  transform: scale(1.1);
}

.ant-menu-item:hover{
  transform: scale(1.1);
}

/* Styles pour la sidebar réduite */
.sidebar-reduced {
  width: 80px;
  padding: 0 10px;
  justify-content: center;
  transition: width 0.3s ease, padding 0.3s ease;
}

.sidebar-reduced .ant-menu-item,
.sidebar-reduced .ant-menu-submenu-title {
  text-align: center;
  line-height: 60px;
  padding: 0;
}

.sidebar-reduced .ant-menu-item span,
.sidebar-reduced .ant-menu-submenu-title span {
  display: none;
}

.logout-item {
  margin-top: auto;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.logout-item:hover {
  background-color: rgba(245, 34, 45, 0.1);
  color: #f5222d;
}

/* Styles pour les petits écrans */
@media screen and (max-width: 414px) {
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 250px;
    height: 100vh;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    z-index: 999;
  }

  .sidebar.visible {
    transform: translateX(0);
  }

  .sidebar-toggle {
    display: none;
  }
}

@media screen and (max-width: 568px) {
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 200px;
    height: 100%;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out, width 0.3s ease;
    z-index: 999;
  }

  .sidebar.visible {
    transform: translateX(0);
  }

  .sidebar-toggle {
    display: none;
  }
}
