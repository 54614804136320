@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Source+Sans+3:wght@700&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

.app-rows{
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.app-container {
  position: relative;
  display: flex;
  width: 100%;
  min-height: 100vh;
}
.app-outlet{
  position: relative;
  overflow: hidden;
  flex: 5;
  width: 100%;
  background: #f8f8f8f1;
  padding: 20px;
}

.hidden-column {
  display: none;
}

.spinner-container{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.spinnerContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  }

.loader-container-center {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.even-row {
  background-color: #f9f9f9 !important;
}

.odd-row {
  background-color: #ffffff !important;
}
