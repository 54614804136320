.client-form-container {
    display: flex;
    justify-content: center;
    padding: 20px;
  }

  .controle_title_rows{
    background: #f2f2f2;
    padding: 10px;
    margin-bottom: 20px;
    .controle_h2{
      font-weight: 300;
      font-size: 15px;
    }
  }
  
  .client-form-wrapper {
    width: 100%;
    max-width: 600px;
    margin: auto;
  }
  
  .submit-button {
    margin-top: 20px;
  }
  