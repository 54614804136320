.rapport-entretien-container{
    padding: 20px;
    background-color: #f5f5f5;
    .title_row{
        background: #fff;
        padding: 10px;
        margin-bottom: 20px;
        border-radius: 5px;
        .title_h1{
            font-size: .9rem;
            font-weight: 300;
        }
    }
}