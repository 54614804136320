.rapportSpecialForm{
    padding: 10px;
/*     background-color: #f5f5f5;
 */    .rapportSpecial_wrapper{
        .h1_rapport{
            font-size: .985rem;
            background-color: #f5f5f5;
            padding: 10px;
            margin-bottom: 20px;
        }
        .rapportSpecial_rows{
            display: flex;
            flex-direction: column;
            gap: 10px;
            width: 100%;
            .rapportSpecial_row{
                width: 100%;
                display: flex;
                gap: 20px;
                justify-content: center;
            }
            .rapportSpecial_row2{
                display: flex;
                flex-direction: column;
                gap: 20px;
            }

        }
    }
}