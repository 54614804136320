.filterTache{
    width: 100%;
    position: relative;
    overflow-x: scroll;
    .filterWrapper{
        display: flex;
        flex-direction: column;
        gap: 10px;
        .filter_rows{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 10px;
            padding: 20px;
            background: #f2f2f2;
            .filter_desc{
                font-size: 1rem;
                font-weight: 300;
            }
        }
    }
}