.detail-upload {
    padding: 20px;
    background-color: #f5f5f5;
  
    .gallery-row {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: stretch;  // Align all items to have the same height
    }
  
    .gallery-card {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      transition: all 0.3s ease-in-out;
  
      &:hover {
        transform: translateY(-8px);
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
      }
  
      .gallery-image {
        width: 100%;
        height: 200px;  // Set a fixed height for the images
        object-fit: cover;  // Ensure the image scales without distortion
        border-radius: 10px;
        cursor: pointer;
        transition: transform 0.3s ease-in-out;
  
        &:hover {
          transform: scale(1.07);
        }
      }
  
      .ant-card-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
      }
  
      .title-wrapper {
        font-weight: 600;
        color: #4b4b4b;
        text-align: center;
        padding-top: 8px;
      }
    }
  
    .view-button,
    .download-button {
      font-size: 16px;
      color: #1e90ff;
      &:hover {
        color: #0056b3;
      }
    }
  
    .loading-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
    }
  
    .download-progress {
      margin-top: 20px;
      text-align: center;
    }
  }
  
  .image-modal {
    .modal-image {
      width: 100%;
      max-width: 100%;
      max-height: 80vh;
      object-fit: contain;
    }
  }
  .gallery-pagination{
    margin: 20px 0;
  }

  .gallery-title {
    font-size: 10px;
    font-weight: 300!important;
    background: #fff;
    text-align: center;
    padding: 10px 0;;
    border-radius: 5px;
    box-shadow: 0px 0px 10px -8px rgba(0, 0, 0, 0.75);
  
    @media (max-width: 768px) {
      font-size: 9px;
    }
  }
  