.controle_forms {
    padding: 20px;
    border-radius: 8px;
    
    }
  .controle_title_row{
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #f2f2f2!important;
      padding: 10px;
      margin-bottom: 20px
    }
  .controle_h2{
    font-weight: 300;
  }
  .controle_title_rows{
    background: #f2f2f2;
    padding: 10px;
    margin-bottom: 20px;
  }
  .controle_h2{
    font-weight: 300;
    font-size: 15px;
  }
  .controle_wrapper {
    max-width: 800px;
    margin: 0 auto;
  }
  
  