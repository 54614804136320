@import '../../style.scss';
.client-row-lefts{
    margin-bottom: 20px;
    padding: 15px;
    width: max-content;
    border-radius: 5px;
    box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
    @include mobileXR{
      width: 100%;
      }
    .client-title{
        display: block;
        margin-bottom: 15px;
        border-bottom: 1px solid #cccccc;
        line-height: 35px;
    }
    .client-row-sous{
        display: grid;
        grid-template-columns: repeat(3,1fr);
        gap: 10px;
        span{
            font-size: .8rem;
            font-weight: 200;
        }
    }
}


.priority-1 {
    background-color: #d3d3d3; // Gris
  }
  
  .priority-2 {
    background-color: #add8e6; // Bleu
  }
  
  .priority-3 {
    background-color: #ffffe0; // Jaune
  }
  
  .priority-4 {
    background-color: #ffa500; // Orange
  }
  
  .priority-5 {
    background-color: #ff4500; // Rouge
  }
  
  .priority-badge {
    border-radius: 4px;
    padding: 2px 6px;
    color: #fff;
    text-align: center;
  }
  
  .button-excel {
    border: 1px solid green;
    color: green;
    transition: all 0.3s ease;
  
    &:hover {
      background-color: green;
      color: white;
    }
  }