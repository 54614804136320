.form-container {
    max-width: 500px;
    margin: auto;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
.controle_title_rows{
    background: #f2f2f2;
    padding: 10px;
    margin-bottom: 20px;
  }

  .controle_h2{
    font-weight: 300;
    font-size: 15px;
  }
  
.form-container:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.warehouse-form {
    animation: fadeIn 0.5s;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
