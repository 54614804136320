.statistique_items{
    height: 100%;
    .statistiqueItems_wrapper{
        height: 100%; 
        .statistiqueItems_rows{
            display: grid;
            grid-template-columns: repeat(2,1fr);
            gap: 10px;
            height: 100%; 
            .statistiqueItems-row{
                position: relative;
                flex: 1;
                overflow: hidden;
                background: #fff;
                box-shadow: 0px 0px 10px -8px rgba(0, 0, 0, 0.75);
                padding: 15px 20px;
                border: 1px solid #f1f1f1;
                border-radius: 20px;
                display: flex;
                justify-content: space-between;
                gap: 10px;
                cursor: pointer;
                transition: transform 0.3s ease, box-shadow 0.3s ease;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                &:hover {
                    transform: translateY(-10px);
                    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
                }
            
                .statistiqueItems-top{
                    display: flex;
                    flex-direction: column;
                    .statistique_row_icon{
                        background: #eeeeee;
                        padding: 5px;
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transition: background 0.3s ease;
    
                        &:hover {
                            background: rgba(1, 20, 129, 0.726);
                        }
                    }
                }
                .statistiqueItems-bottom{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .row_title{
                        font-size: 1rem;
                        font-weight: 300;
                        letter-spacing: 1px;
                        color: #555;
                    }
                    .statistique_h2{
                        color: rgba(1, 20, 129, 0.726);
                        font-size: 1.8rem;
                        letter-spacing: 4px;
                        font-weight: 900;
                    }
                    .row_desc{
                        font-size: .7rem;
                        color: #555;
                        letter-spacing: 1px;
                        font-weight: 200;
                    }
                }
            }
            .items_orange{
                border-color: #f4a361d7;
                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 90px;
                    height: 100%;
                    background: #f4a3613f;
                    clip-path: polygon(100% 1%, 100% 49%, 100% 100%, 0 44%, 0 9%, 0% 0%); // Angled shape
    
    /*                 clip-path: polygon(85% 0, 100% 0, 100% 100%, 0 0%); // Angled shape
     */                pointer-events: none;
                }
            }
            .items_blue{
                border-color: #6a8cafd3;
                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 90px;
                    height: 100%;
                    background: #6a8caf38;
                    clip-path: polygon(100% 1%, 100% 49%, 100% 100%, 0 44%, 0 9%, 0% 0%); // Angled shape
                    pointer-events: none;
                }
            }
            .items_yellow{
                border-color: #f9c64fb0;
                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 90px;
                    height: 100%;
                    background: #f9c64f5b;
                    clip-path: polygon(100% 1%, 100% 49%, 100% 100%, 0 44%, 0 9%, 0% 0%); // Angled shape
                    pointer-events: none;
                }
            }
            .items_red{
                border-color: #e63947cc;
                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 90px;
                    height: 100%;
                    background: #e6394723;
                    clip-path: polygon(100% 1%, 100% 49%, 100% 100%, 0 44%, 0 9%, 0% 0%); // Angled shape
    
    /*                 clip-path: polygon(85% 0, 100% 0, 100% 100%, 0 0%); // Angled shape
     */                pointer-events: none;
                }
            }
        }
    }
}