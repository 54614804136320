.password-reset-container {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
  
    .password_reset {
      display: flex;
      align-items: center;
      background: #fff;
      padding: 40px;
      border-radius: 16px;
/*       box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
 */      max-width: 900px;
      width: 100%;
      position: relative;
      transition: box-shadow 0.3s ease;
  
      &:hover {
        box-shadow: 0 12px 36px rgba(0, 0, 0, 0.15);
      }

      .reset_left {
        flex: 1;
        margin-right: 20px;
  
        img.reset_img {
          max-width: 100%;
          border-radius: 12px;
        }
      }
  
      .reset_right {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
  
        .reset_content {
          max-width: 400px;
          margin: auto;
  
          .reset_title {
            font-size: 24px;
            margin-bottom: 12px;
            color: #333;
            text-align: center;
          }
  
          .reset_description {
            font-size: 16px;
            margin-bottom: 24px;
            color: #666;
            text-align: center;
          }
  
          .reset_form {
            .reset_input {
              border-radius: 8px;
            }
  
            .reset_button {
              border-radius: 8px;
              background-color: #1890ff;
              &:hover {
                background-color: #40a9ff;
              }
            }
          }
        }
      }
    }
  
    // Responsive adjustments
    @media (max-width: 768px) {
      .password_reset {
        flex-direction: column;
        padding: 20px;
  
        .reset_left {
          margin-right: 0;
          margin-bottom: 20px;
          img.reset_img {
            height: auto;
          }
        }
      }
    }
  
    @media (max-width: 576px) {
      .password_reset {
        padding: 15px;
        box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
      }
  
      .reset_content {
        max-width: 100%;
      }
  
      .reset_title {
        font-size: 20px;
      }
  
      .reset_description {
        font-size: 14px;
      }
    }
  }
  