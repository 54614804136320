@import '../../style.scss';

.forgot-password-container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
/*   background: linear-gradient(135deg, #f5f7fa, #c3cfe2);
 */  padding: 20px;
  @include mobileXR{
    background: #ffffff;
  }

  .forgot_password {
    display: flex;
    align-items: center;
    background: #fff;
    padding: 40px;
    border-radius: 16px;
/*     box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1); */
    max-width: 900px;
    width: 100%;
    position: relative;
    transition: box-shadow 0.3s ease;
    @include mobileXR{
        flex-direction: column;
        padding: 20px;
      }

    &:hover {
      box-shadow: 0 12px 36px rgba(0, 0, 0, 0.15);
    }

    .forgot_left {
      flex: 1;
      margin-right: 20px;
      @include mobileXR{
        margin-right: 0;
        margin-bottom: 20px;
      }
      img.forgot_img {
        max-width: 100%;
        border-radius: 12px;
        @include mobileXR{
            height: auto;
        }
      }
    }

    .forgot_right {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .forgot_content {
        max-width: 400px;
        margin: auto;

        .forgot_title {
          font-size: 24px;
          margin-bottom: 12px;
          color: #333;
          text-align: center;
        }

        .forgot_description {
          font-size: 16px;
          margin-bottom: 24px;
          color: #666;
          text-align: center;
        }

        .forgot_form {
          .forgot_input {
            border-radius: 8px;
          }

          .forgot_button {
            border-radius: 8px;
            background-color: #1890ff;
            &:hover {
              background-color: #40a9ff;
            }
          }
        }
      }
    }
  }
}
