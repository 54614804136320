@import '../../style.scss';

.statistique {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .statistique_rows {
        display: flex;
        gap: 20px;
        width: 100%;

        @include mobileXR {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }

        .statistique_row {
            position: relative;
            flex: 1;
            overflow: hidden;
            background: #fff;
            box-shadow: 0px 0px 10px -8px rgba(0, 0, 0, 0.75);
            padding: 15px 20px;
            border: 1px solid #f1f1f1;
            border-radius: 20px;
            display: flex;
            justify-content: space-between;
            gap: 10px;
            cursor: pointer;
            transition: transform 0.3s ease, box-shadow 0.3s ease;

            &:hover {
                transform: translateY(-10px);
                box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
            }

            &::after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                width: 80px;
                height: 100%;
                background: rgba(0, 0, 255, 0.034);
                clip-path: polygon(0 0, 100% 0, 100% 100%, 0 75%);
                pointer-events: none;
            }

            @include mobileXR {
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }

            .statistique_row_left {
                display: flex;
                justify-content: center;
                align-items: center;

                .statistique_row_icon {
                    background: #eeeeee;
                    padding: 5px;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: background 0.3s ease;

                    &:hover {
                        background: rgba(1, 20, 129, 0.726);
                    }
                }
            }

            .statistique_row_right {
                @include mobileXR {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                }

                .row_title {
                    font-size: 1rem;
                    font-weight: 300;
                    letter-spacing: 1px;
                    color: #555;
                }

                .statistique_h2 {
                    color: rgba(1, 20, 129, 0.726);
                    font-size: 1.8rem;
                    letter-spacing: 4px;
                    font-weight: 900;
                }

                .row_desc {
                    font-size: .7rem;
                    color: #555;
                    letter-spacing: 1px;
                    font-weight: 200;
                }
            }
        }
        .static_bleu{
            &::after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                width: 110px;
                height: 100%;
                background: #3A5FCD1A;
                clip-path: polygon(100% 1%, 100% 49%, 100% 100%, 0 44%, 0 9%, 0% 0%); // Angled shape

/*                 clip-path: polygon(85% 0, 100% 0, 100% 100%, 0 0%); // Angled shape
 */                pointer-events: none;
            }
        }
        .static_gris{
            &::after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                width: 110px; // Adjust width as needed
                height: 100%;
                background: #7070701A;// Adjust color as needed
                clip-path: polygon(100% 1%, 100% 49%, 100% 100%, 0 44%, 0 9%, 0% 0%); // Angled shape
                pointer-events: none; // Ensures it doesn’t interfere with clicks
            }
        }
        .static_orange{
            &::after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                width: 110px; // Adjust width as needed
                height: 100%;
                background: #FF8C421A;// Adjust color as needed
                clip-path: polygon(100% 1%, 100% 49%, 100% 100%, 0 44%, 0 9%, 0% 0%); // Angled shape
                pointer-events: none; // Ensures it doesn’t interfere with clicks
            }
        }
        .static_cyan{
            &::after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                width: 110px;
                height: 100%;
                background: #2BA4C61A;// Adjust color as needed
                clip-path: polygon(100% 1%, 100% 49%, 100% 100%, 0 44%, 0 9%, 0% 0%); // Angled shape
                pointer-events: none; // Ensures it doesn’t interfere with clicks
            }
        }
    }

    .statistique_bottom {
        display: flex;
        gap: 10px;
        @include mobileXR {
            flex-direction: column;
        }

        .statistique_bottom_rows1{
            flex: 2;
            background: #fff;
            box-shadow: 0px 0px 10px -8px rgba(0, 0, 0, 0.75);
            padding: 20px;
            border: 1px solid #f1f1f1;
            border-radius: 20px;
        }
        .statistique_bottom_rows2{
            flex: 1;
            background: #fff;
            box-shadow: 0px 0px 10px -8px rgba(0, 0, 0, 0.75);
            padding: 20px;
            border: 1px solid #f1f1f1;
            border-radius: 20px;
        }
    }

    .statistique_row .ant-skeleton {
        height: 100%;
        width: 100%;
        border-radius: 25px;
    }
}
