.rapport_declaration{
    width: 100%;
    position: relative;
    background: #fff;
    padding: 20px;
    box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    border-radius: 5px;
}