.clients {
    height: 800px;
    width: 100%;
    overflow-y: scroll;

    .row {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-top: 20px;
      justify-content: space-between;
      border: 1px solid #ececec;
      border-radius: 10px;
      .table-container {
        flex: 1;
        background-color: #fff;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        transition: box-shadow 0.3s ease;
  
        &:hover {
          box-shadow: 0 6px 18px rgba(0, 0, 0, 0.15);
        }
        .row-title{
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 15px;
          border-bottom: 2px solid #e8e8e8;
          padding-bottom: 10px;
          .table-title {
            font-size: 17px;
            font-weight: 600;
            color: #333;
          }
        }
      }
  
      .ant-table {
        &-thead > tr > th {
          background-color: #f7f7f7;
          color: #333;
          border-bottom: 1px solid #e8e8e8;
          font-size: .8rem;
        }
  
        &-tbody > tr > td {
          border-bottom: 1px solid #e8e8e8;
          font-size: .8rem;
        }
      }
  
      .ant-tag {
        font-size: 12px;
        border-radius: 4px;
      }
    }
  }
  