.rapportClotureTemplForm{
    display: flex;
    flex-direction: column;
    gap: 20px;
    .rapport_rows{
        background: #f2f2f2;
        padding: 10px;
        .rapport_h1{
            font-weight: 300;
            font-size: 15px;
        }
    }
    .rapportCloture_wrapper{
        display: flex;
        flex-direction: column;
        gap: 20px;
        .rapportCloture_rows{
            display: flex;
            justify-content: space-between;
            gap: 20px;
            .rapportCloture_tops{
                display: flex;
                gap: 10px;
                .rapportCloture_top{
                    display: flex;
                    gap: 10px;
    
                    label{
                        display: flex;
                        align-items: center;
                        gap: 5px;
                    }
                }
            }
            .rapportCloture_left{
                display: flex;
                gap: 10px;
            }
        }
    }
}