.controle_form {
    .controle_wrapper {
        .custom-form {
            .ant-form-item {
                margin-bottom: 16px; // Espace entre les champs
                .ant-form-item-label {
                    label {
                        
                    }
                }
                .ant-form-item-control {
                    .ant-input, .ant-select-selector, .ant-input-textarea {
                        resize: none;
                    }
                }
            }
            .button-group {
                display: flex;
                justify-content: center;
            }
        }
    }
}
